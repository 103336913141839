import getUserConfiguration from '../actions/getUserConfiguration';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getIdleSessionTimeout } from '../utils/getIdleSessionTimeout';

export function isSharedABTEnabled(): boolean {
    return !!(
        isFeatureEnabled('auth-sharedActivityBasedTimeout') &&
        (getUserConfiguration().PolicySettings?.IsSharedActivityBasedTimeoutEnabled ||
            !!getIdleSessionTimeout())
    );
}
