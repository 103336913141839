import {
    shouldShowRSVP,
    prepareRSVPItemIfNeeded,
    loadMeetingForMessageIfNeeded,
} from './RSVPUtils';
import type { TableView } from 'owa-mail-list-store';
import type { ConversationType } from 'owa-graph-schema';
import type ItemId from 'owa-service/lib/contract/ItemId';

/**
 * Determines whether to add the conversation to the fetch list and returns a flag
 * indicating whether to show the RSVP or not
 * It might happen that this return true then the client will hide it after checking
 * other properties that returns as part of LoadItem
 * @param conversationType Conversation payload for the item to be added or updated
 * @param tableView The Table view
 * @return shouldShowRSVP flag
 */
export default function getShouldShowRSVPForConversationAndPrepareItem(
    conversationType: Partial<ConversationType>,
    tableView: TableView
): boolean {
    // Search scenarios and Suggestions click will bring conversation with no ItemClasses
    if (!conversationType.ItemClasses || conversationType.ItemClasses.length === 0) {
        return false;
    }

    const itemId = (conversationType?.ItemIds?.[0] as ItemId)?.Id;
    const itemClass = conversationType.ItemClasses[0];

    if (
        itemId &&
        conversationType?.InstanceKey &&
        shouldShowRSVP(conversationType.InstanceKey, itemId, itemClass, tableView)
    ) {
        // Do all work needed in order to show the Meeting action button
        // i.e. Fetch item with required properties
        prepareRSVPItemIfNeeded(conversationType.InstanceKey, itemId, itemClass, tableView);
        return true;
    }

    // even if we don't want to show RSVP button we might still want to show calendar peek in CITI card, so check if we need to fetch meeting message
    if (itemId && conversationType?.InstanceKey) {
        loadMeetingForMessageIfNeeded(
            conversationType.ItemClasses,
            conversationType.InstanceKey,
            itemId,
            tableView
        );
    }

    return false;
}
